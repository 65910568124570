.pmivr-diagram {
	background-image: url(../../../src/static/images/bg-dotted.png);
	background-repeat: repeat;
	background-color: $bg-color-secondary !important;

	// iframe alignment and styling
	.drafting-iframe {
		border: 4px solid black;
		margin-right: 212px;
	}

	// cancel button for drafting
	.drafting-cancel {
		margin-top: -10rem;
		margin-left: 57rem;
	}

	// for hiding the incoming flow lines for a subprocess
	.incoming-flow-line {
		display: none !important;
	}

	// for the alignment of context pad icons on diagram page
	.bpmn-icon-connection-multi,
	.pad-icons {
		float: left;
	}

	.control-menu-outer {

		&.control-menu-collapse {
			margin-left: -15.625rem;
			transform: translate3d(-100%, 0, 0);
			transition: all 1s;
			-webkit-transition: all 1s;
			-webkit-transform: translate3d(-100%, 0, 0);
		}

		.control-menu {
			width: 15.625rem;
			background-color: $bg-color-white;
			box-shadow: 2px 0px 2px 0px rgba(0, 0, 0, 0.04);
			-webkit-box-shadow: 2px 0px 2px 0px rgba(0, 0, 0, 0.04);
			-moz-box-shadow: 2px 0px 2px 0px rgba(0, 0, 0, 0.04);
			height: calc(100vh - 0rem);

			.scroll {
				max-height: calc(100vh - 4.3rem);
				overflow-y: auto;
			}

			.control-menu-btn {
				font-size: 13px !important;
				padding: 0.2rem 0.625rem;

				span {
					margin-left: 8px;
				}

				&:hover {
					border-radius: 5px;
					background-color: $hover-control-btn;
					color: $color-primary !important;
					font-family: $font-medium;
				}
			}

			button {
				color: $color-secondary;
				border: 1px solid transparent;
				margin: 1rem 0;
				background-color: $bg-color-white;
				line-height: 1.875rem;
				padding: 0.125rem 0.625rem;
				width: 100%;
				margin-bottom: 0.625rem;
				border-radius: 0.1875rem;

				i {
					font-size: 1.25rem;
					width: 0px;
					float: left;
				}

				i.bi-three-dots-vertical {
					font-size: 0.9375rem;
					width: 0.5rem;
					float: right;
					position: relative;
				}

				span {
					text-align: left;
					width: 80%;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}

			& {
				ul {
					width: 11.8rem;
					margin: auto;

					li {
						button {
							background: $bg-color-secondary;
							border: none !important;
							box-shadow: none !important;
						}

						&:first-child {
							button {
								border-top-right-radius: 0px;
								border-bottom-right-radius: 0px;
							}
						}

						&:last-child {
							button {
								border-top-left-radius: 0px;
								border-bottom-left-radius: 0px;
							}
						}

						button.active {
							background: $bg-color-primary;
							color: $color-white;
							border-radius: 0.3rem;
							-webkit-box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.13) !important;
							-moz-box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.13) !important;
							box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.13) !important;
						}
					}
				}
			}

			.create-sub-process i {
				font-size: 25px;
				margin-top: -8px !important;
			}

			.add-sub-process-btn {
				margin-bottom: -15px;
				margin-top: -2px;
			}
		}
	}

	.toggle-arrow {
		box-shadow: 3px 1px 5px -1px rgb(0 0 0 / 10%);
		position: absolute;
		background-color: $bg-color-white;
		top: 1.2rem;

		i {
			color: $color-primary;
			padding: 3px 0;
		}
	}

	.diagram-wrapper {
		height: 100% !important;

		.diagram {
			flex-grow: 1;
			height: calc(100vh - 4.5rem);
		}

		.count {
			background-color: $icon-btn-hover;
			border-radius: 0.3rem;
			color: $color-black;
		}

		.last-saved {
			display: "flex";
			text-align: "center";
			float: "right";

			span {
				margin-right: 10px;
			}
		}
	}

	.diagram-properties {
		position: absolute;
		top: 0;
		right: 0;
		display: none;
	}

	// For working of icons on bottom of components box.
	.djs-element>.djs-hit-all {
		pointer-events: none !important;
	}

	// On double-clicking the component, renaming the component should work.
	.djs-element>.djs-visual {
		pointer-events: all !important;
	}

	// To limit the heading height while entering the name of component
	.djs-direct-editing-content {
		max-height: 99px;
		overflow-y: auto;
	}

	.pmivr-btn-icon {
		&:hover {
			border-radius: 0px !important;
		}
	}
}

.diagram-confirm-modal {

	.modal-dialog {
		max-width: 620px !important;
	}

	.change-logs {
		border-bottom: 1px solid $border-color-secondary;
		display: flex;
		align-items: center;
		padding: 1rem 0;

		&:first-child {
			padding-top: 0px;
		}
	}

	.modal-content {
		max-height: 39.4rem !important;
	}

	button {
		padding: 0.7rem;
	}
}

.pmivr-modal {
	.doc-comments {
		font-size: 0.8rem !important;
		width: 100%;
		font-family: $font-medium;
		border-radius: 0.2rem;
		padding: 0.5rem 0 0.5rem 0.5rem;
		border: solid 1px $border-color-secondary !important;
	}
}